import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from '@papertrailio/react-web-core/components/Button'

import Link from '../Link'

const buttonPadding = { padding: '2px 10px' }

const ButtonStyled = styled(Button)`
  padding: 2px 10px;
`

const Spacer = styled.span`
  padding: 2px 4px;
`

const List = styled.ul`
  margin: 0;

  @media only screen and (max-width: 750px) {
    display: none;
  }
`

const ListItem = styled.li`
  display: inline-block;
  padding: 0 6px;
  margin: 0;
`

const maxPagesPerSide = 3

const maxPagesVisible = maxPagesPerSide * 2

const moreThanDisplayable = totalPages => totalPages > maxPagesVisible

const More = ({ start, pagePathBase, totalPages }) => (
  <ListItem>
    <ButtonStyled
      theme="white"
      to={
        start
          ? `${pagePathBase}/#content`
          : `${pagePathBase}${totalPages}/#content`
      }
      Link={Link}
      overideTextColor="secondary1"
    >
      ...
    </ButtonStyled>
  </ListItem>
)

More.propTypes = {
  start: PropTypes.bool,
  pagePathBase: PropTypes.string.isRequired,
  totalPages: PropTypes.number.isRequired,
}

const PageNumber = ({ page, currentPage, pagePathBase }) => (
  <ListItem>
    <ButtonStyled
      theme={page === currentPage ? 'primaryBlue' : 'white'}
      to={
        page === 1
          ? `${pagePathBase}/#content`
          : `${pagePathBase}${page}/#content`
      }
      Link={Link}
      overideTextColor={page === currentPage ? null : 'secondary1'}
    >
      {page}
    </ButtonStyled>
  </ListItem>
)

PageNumber.propTypes = {
  page: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  pagePathBase: PropTypes.string.isRequired,
}

const PaginationPageList = ({ pagePathBase, currentPage, totalPages }) => {
  const allPages = Array.from(Array(totalPages).keys()).reduce(
    (accumulator, index) => {
      const page = index + 1

      accumulator.push(
        <PageNumber
          key={page}
          page={page}
          currentPage={currentPage}
          pagePathBase={pagePathBase}
        />
      )

      return accumulator
    },
    []
  )

  let displayablePages = allPages

  if (moreThanDisplayable(totalPages)) {
    if (currentPage <= maxPagesPerSide) {
      // We are viewing one of the early pages, so start from page 1
      displayablePages = [
        ...allPages.slice(0, maxPagesVisible),
        <More key="end" pagePathBase={pagePathBase} totalPages={totalPages} />,
      ]
    }
 else if (currentPage >= totalPages - maxPagesPerSide + 1) {
      // We are viewing one of the later pages, so start show up to the end
      displayablePages = [
        <More
          start
          key="start"
          pagePathBase={pagePathBase}
          totalPages={totalPages}
        />,
        ...allPages.slice(-maxPagesVisible),
      ]
    }
 else {
      displayablePages = [
        <More
          start
          key="start"
          pagePathBase={pagePathBase}
          totalPages={totalPages}
        />,
        ...allPages.slice(
          currentPage - maxPagesPerSide,
          currentPage + maxPagesPerSide - 1
        ),
        <More key="end" pagePathBase={pagePathBase} totalPages={totalPages} />,
      ]
    }
  }

  return <List>{displayablePages}</List>
}

PaginationPageList.propTypes = {
  pagePathBase: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
}

export default PaginationPageList
