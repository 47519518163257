import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'

import ContentContainer from '@papertrailio/react-web-core/components/ContentContainer'
import ContentAnchor from '@papertrailio/react-web-core/components/ContentAnchor'
import BottomCTA from '@papertrailio/react-web-core/components/text/BottomCTA'
import Button from '@papertrailio/react-web-core/components/Button'
import { color, spacing } from '@papertrailio/react-web-core/style/theme'
import ProductListItem from '@papertrailio/react-web-core/components/product/ProductListItem'

import Layout from '../components/Layout'
import HeaderContent from '../components/HeaderContent'
import Testimonial from '../components/Testimonial'
import Pagination from '../components/pagination/Pagination'
import Helmet from '../components/Helmet'
import Link from '../components/Link'
import slug from '../utils/slug'

const List = styled.ul`
  margin-left: 0;
  margin-bottom: ${spacing.base * 2}px;
`

const TextInputStyled = styled.input`
  padding-left: ${spacing.base * 1.5}px;
  border: 1px solid ${rgba(color.secondary1, 0.1)};
  width: 100%;
`

const Count = styled.span`
  color: ${color.primaryBlue};
`

const RemoveFilterLink = styled(Link)`
  margin: -${spacing.base}px 0 ${spacing.base}px 0;
  display: block;
`

const listTemplate = ({ pageContext }) => {
  const {
    manufacturer,
    manufacturers,
    products,
    pagination,
    pagePathBase,
    pagePath,
    keyValue,
  } = pageContext

  return (
    <Layout
      headerContent={() => (
        <HeaderContent
          listPagePath={pagePath}
          manufacturer={manufacturer}
          manufacturers={manufacturers}
        />
      )}
    >
      <Helmet
        title={`${keyValue
          ? `${manufacturer} Products with ${keyValue.label}: ${keyValue.value
          }`
          : `${manufacturer} Products`
          }${pagination.currentPage > 1 ? ` - Page ${pagination.currentPage}` : ''
          }`}
        description={`View details of the ${pagination.count > 1
          ? `${pagination.count} ${manufacturer} Products`
          : `${manufacturer} Product`
          } ${keyValue ? `with ${keyValue.label}: ${keyValue.value}` : ''
          } in the Product Directory, and easily import ${pagination.count > 1 ? 'them' : 'it'
          } to Papertrail.`}
      />

      <ContentContainer>
        <ContentAnchor />
        {!keyValue && (
          <h2>
            Showing <Count>{pagination.count}</Count> {manufacturer}{' '}
            {pagination.count === 1 ? 'Product' : 'Products'}
          </h2>
        )}

        {keyValue && (
          <Fragment>
            <h2>
              Showing <Count>{pagination.count}</Count> {manufacturer}{' '}
              {pagination.count === 1 ? 'Product' : 'Products'} with{' '}
              {keyValue.label}: {keyValue.value}
            </h2>
            <RemoveFilterLink to={`/${slug(manufacturer)}/#content`}>
              {`Remove '${keyValue.label}' filter`}
            </RemoveFilterLink>
          </Fragment>
        )}

        <List>
          {products.map(product => (
            <ProductListItem
              key={product.node.id}
              product={{ ...product.node, unique: false }}
              manufacturer={manufacturer}
              listPagePath={pagePath}
            />
          ))}
        </List>

        <Pagination pagination={pagination} pagePathBase={pagePathBase} />
      </ContentContainer>
      <Testimonial />

      <BottomCTA>
        <Button
          theme="primaryRed"
          href="https://www.papertrail.io/book-demo?utm_source=product-directory&utm_campaign=book-demo"
          track={{
            category: 'demoLink',
            action: 'click',
            label: 'bottom',
          }}
          Link={Link}
        >
          Book a demo
        </Button>
      </BottomCTA>
    </Layout>
  )
}

listTemplate.propTypes = {
  pageContext: PropTypes.shape({
    pagePathBase: PropTypes.string.isRequired,
    pagePath: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    manufacturers: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    pagination: PropTypes.shape({
      count: PropTypes.number.isRequired,
      currentPage: PropTypes.number.isRequired,
      perPage: PropTypes.number.isRequired,
      totalPages: PropTypes.number.isRequired,
    }).isRequired,
    keyValue: PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default listTemplate
