import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Button from '@papertrailio/react-web-core/components/Button'
import { color, spacing } from '@papertrailio/react-web-core/style/theme'

import Link from '../Link'
import PaginationPageList from './PaginationPageList'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.base * 2}px;
`

const ButtonStyled = styled(Button)`
  padding: 2px 10px;
  visibility: ${props => (props.hidden ? 'hidden' : 'visible')};
`

const Pagination = ({
  pagePathBase,
  pagination: { currentPage, totalPages },
}) =>
  totalPages === 1 ? null : (
    <Container>
      <ButtonStyled
        theme="secondary7"
        to={
          currentPage === 2
            ? `${pagePathBase}/#content`
            : `${pagePathBase}${currentPage - 1}/#content`
        }
        Link={Link}
        overideTextColor="secondary1"
        hidden={currentPage === 1 ? true : false}
        disabled={currentPage === 1 ? true : false}
      >
        ← Prev
      </ButtonStyled>

      <PaginationPageList
        pagePathBase={pagePathBase}
        currentPage={currentPage}
        totalPages={totalPages}
      />

      <ButtonStyled
        theme="secondary7"
        to={`${pagePathBase}${currentPage + 1}/#content`}
        Link={Link}
        overideTextColor="secondary1"
        hidden={currentPage === totalPages ? true : false}
        disabled={currentPage === totalPages ? true : false}
      >
        Next →
      </ButtonStyled>
    </Container>
  )

Pagination.propTypes = {
  pagination: PropTypes.shape({
    currentPage: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
  }).isRequired,
  pagePathBase: PropTypes.string.isRequired,
}

export default Pagination
